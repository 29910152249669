<template>
  <!-- dialog弹框 -->
  <el-dialog
    :title="this.dialogText.dialogTitle"
    :visible.sync="dialogVisible"
    width="570px"
    center
    @open="getMsg"
    @close="resetForm"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <!-- 表单区域 -->
    <el-form
      :model="dialogFormQueData"
      ref="dialogFormRef"
      label-width="120px"
      class="demo-ruleForm"
      style="padding: 10px 30px 0 30px"
    >
      <div v-for="(item, index) in dialogFormQueData" :key="index">
        <div>第{{ index }}题</div>
        <el-form-item
          label="试题开放时间："
          :prop="`${index}.paperDownloadBegin`"
        >
          <el-date-picker
            ref="paperDownloadBegin"
            v-model="dialogFormQueData[index].paperDownloadBegin"
            placeholder="请选择试题开放时间"
            type="datetime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="试题截止时间："
          :prop="`${index}.paperDownloadEnd`"
        >
          <el-date-picker
            ref="paperDownloadEnd"
            v-model="dialogFormQueData[index].paperDownloadEnd"
            placeholder="请选择试题截止时间"
            type="datetime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="上传图片"
          :prop="`${index}.paperUrl`"
          class="bgPic"
        >
          <div class="image_add">
            <div
              v-for="(item2, index2) in dialogFormQueData[index]['paperUrl']"
              :key="index2"
            >
              <img :src="item2" class="avatar" />
              <div class="del_btn" @click="delImg(item2, index)">删除</div>
            </div>

            <el-upload
              v-model="fileList[index]"
              class="avatar-uploader"
              action="#"
              :ref="`upload_${index}`"
              :auto-upload="false"
              :on-change="(file) => handleLoad(file, index)"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <!-- 底部按钮区域 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancelBtn">取 消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="submitForm"
        :disabled="isDisable"
        >{{ this.dialogText.btnText }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  uploadQue,
  uploadUrlOss,
  uploadBeginAndEndTime,
} from "r/admin/examination";

import { SERVER_URL } from "../../../config/server";
import { imageToBase64, base64ToFile } from "common/utils";

export default {
  name: "questionDialog",
  props: {
    dialogVisibleQue: Boolean,
    dialogText: Object,
    examId: String,
    // Question:Object,
    subjectUuid: String,
    // paperDownloadBegin: String,
    // paperDownloadEnd: String,
    paperUrl: String,
    // 刷新方法
    getExamSubjectLists: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      serverUrl: SERVER_URL,
      allObjectLists: [], // 全部科目数据列表
      dialogVisible: this.dialogVisibleQue, // 控制dialog弹框显示与隐藏
      dialogFormQue: {
        examId: this.examId,
        subjectUuid: "",
        paperDownloadBegin: "",
        paperDownloadEnd: "",
        file: "",
      }, // 数据对象
      dialogFormQueBase: {},
      dialogFormQueData: {
        1: {
          paperDownloadBegin: "",
          paperDownloadEnd: "",
          paperUrl: [],
        },
        2: {
          paperDownloadBegin: "",
          paperDownloadEnd: "",
          paperUrl: [],
        },
      },
      dialogVisibleImg: false,
      imageUrl: [], //上传图片list
      resultPicture: "",
      baseRule: {
        paperDownloadBegin: [
          { required: true, message: "请选择试题开放时间", trigger: "blur" },
        ],
        paperDownloadEnd: [
          { required: true, message: "请选择试题截止时间", trigger: "blur" },
        ],
        pageUrl: [{ required: true, message: "请上传图片" }],
      },
      fileList: {
        1: [],
        2: [],
      },
      dialogFormRules: {
        1: {
          paperDownloadBegin: [
            { required: true, message: "请选择试题开放时间", trigger: "blur" },
          ],
          paperDownloadEnd: [
            { required: true, message: "请选择试题截止时间", trigger: "blur" },
          ],
          pageUrl: [{ required: true, message: "请上传图片" }],
        },
        2: {
          paperDownloadBegin: [
            { required: true, message: "请选择试题开放时间", trigger: "blur" },
          ],
          paperDownloadEnd: [
            { required: true, message: "请选择试题截止时间", trigger: "blur" },
          ],
          pageUrl: [{ required: true, message: "请上传图片" }],
        },
      }, // 表单验证规则
      isDisable: false, // 提交按钮是否可点击（防重复点击）
      paperUrls: {},
      quesNum: 0,
    };
  },

  created() {},
  watch: {
    dialogVisibleQue() {
      this.dialogVisible = this.dialogVisibleQue;
    },
    subjectUuid(val) {
      this.dialogFormQue.subjectUuid = val;
    },
    // paperDownloadBegin(val) {
    //   // console.log(val, "val");
    //   this.dialogFormQue.paperDownloadBegin = val;
    // },
    // paperDownloadEnd(val) {
    //   // console.log(val, "val");
    //   this.dialogFormQue.paperDownloadEnd = val;
    // },
    paperUrl(val) {
      console.log(typeof val, val, "val");
      if (val) {
        this.dialogFormQueBase = JSON.parse(val);
      }
    },
  },
  methods: {
    getMsg() {
      let paperUrls = this.dialogFormQueBase;
      console.log(paperUrls);
      let rules = {};
      let fileList = {};
      console.log(Object.keys(paperUrls));
      if (paperUrls != null && Object.keys(paperUrls).length > 0) {
        for (let key in paperUrls) {
          paperUrls[key]["paperUrl"] =
            paperUrls[key]["paperUrl"].length > 0
              ? paperUrls[key]["paperUrl"].split(",")
              : [];
          rules[key] = this.baseRule;
          console.log(rules[key]);
          fileList[key] = [];
        }
        this.dialogFormQueData = paperUrls;
        this.dialogFormRules = rules;
        this.fileList = fileList;
      }
    },
    // 重置表单
    resetForm() {
      this.$refs["dialogFormRef"].resetFields();
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeDialogQue", this.dialogVisible);
    },

    // 图片上传成功前钩子
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpg";
      const isPng = file.type === "image/png";
      const isJPEG = file.type === "image/jpeg";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng && !isJPEG) {
        this.$message.info("上传图片只能是 JPG,JPEG或png 格式!");
      }
      if (!isLt10M) {
        this.$message.info("上传图片大小不能超过 10MB!");
      }
      return (isJPG || isPng || isJPEG) && isLt10M;
    },
    // 上传
    handleLoad(file, index) {
      console.log(file, index);
      let formData2 = new FormData();
      formData2.append("examId", this.dialogFormQue.examId);
      formData2.append("subjectUuid", this.dialogFormQue.subjectUuid);
      formData2.append("file", file.raw);
      uploadUrlOss(formData2).then((res) => {
        if (res.data.code != 200) {
          return this.$message.error(res.data.message);
        }
        this.dialogFormQueData[index]["paperUrl"].push(res.data.data);
        this.$message.success(res.data.message);
        this.fileList[index] = [];
      });
    },
    delImg(item, index) {
      console.log(item);
      this.dialogFormQueData[index]["paperUrl"] = this.dialogFormQueData[index][
        "paperUrl"
      ].filter((items) => {
        return items !== item;
      });
      console.log(this.dialogFormQueData[index]["paperUrl"]);
    },
    // 确定,提交表单
    submitForm() {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);

      this.$refs["dialogFormRef"].validate((valid) => {
        if (!valid) return;
        let paperUrls = this.dialogFormQueData;
        let paperUrlObj = this.dialogFormQueData;
        for (let key in paperUrls) {
          // if (paperUrls[key].paperUrl.length == 0) {
          //   return this.$message.info(`请上传第${key}题图片`);
          // }
          if (
            paperUrls[key].paperDownloadBegin != "" &&
            paperUrls[key].paperDownloadBegin != ""
          ) {
            paperUrls[key].paperDownloadBegin = this.dayjs(
              paperUrls[key].paperDownloadBegin
            ).format("YYYY-MM-DD HH:mm:ss");
            paperUrls[key].paperDownloadEnd = this.dayjs(
              paperUrls[key].paperDownloadEnd
            ).format("YYYY-MM-DD HH:mm:ss");

            //校验结束时间是否大于开始时间
            // if(new Date(this.dialogFormQue.paperDownloadBegin).getTime()<(new Date()).getTime()) return this.$message.error('开考时间不能早于此时！')
            if (
              new Date(paperUrls[key].paperDownloadBegin).getTime() >
              new Date(paperUrls[key].paperDownloadEnd).getTime()
            ) {
              this.$message.error("试题开放时间必须早于试题截止时间！");
              return;
            }
          }

          let pageUrl = paperUrlObj[key].paperUrl
            .map((item) => {
              return item.split("/images/")[1].split("?")[0];
            })
            .join(",");
          paperUrls[key].paperUrl = pageUrl;
          console.log(paperUrls[key].paperUrl);
        }
        paperUrls["subjectUuid"] = this.dialogFormQue.subjectUuid;
        uploadBeginAndEndTime(paperUrls).then((res) => {
          if (res.data.code != 200) {
            return this.$message.error(res.data.message);
          }
          this.$message.success(res.data.message);
        });
        this.resetForm();
        this.cancelBtn();
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/dialog";
// 删除
.del_btn {
  cursor: pointer;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 300px;
  height: 200px;
  display: block;
}
</style>
