<template>
  <div class="continer">
    <!-- card面板 -->
    <el-card class="box-card">
      <!-- 头部按钮 -->
      <div slot="header" class="clearfix">
        <el-row :gutter="20">
          <!-- span属性指的是占据多少列 -->
          <el-col :span="6">
            <el-button
              type="text"
              icon="el-icon-arrow-left"
              @click="$router.back()"
              >返回
            </el-button>
          </el-col>
          <el-col :span="12">
            <!-- 考试名称-考试科目-监控组号 -->
            {{ this.$route.query.name }}
          </el-col>
        </el-row>
      </div>

      <div class="text item">
        <div class="body-header">
          <el-row>
            <!-- span属性指的是占据多少列 -->
            <el-col>
              <el-button
                icon="el-icon-plus"
                type="primary"
                class="hbtn"
                @click="openAddExamSubject()"
              >
                新建科目
              </el-button>
              <el-button
                icon="el-icon-close"
                type="primary"
                class="hbtn"
                @click="removeExamSubjectBatch()"
              >
                删除科目
              </el-button>
            </el-col>
          </el-row>
        </div>

        <!-- 主体table表格 -->
        <el-table
          :data="examSubjectLists"
          style="width: 100%"
          stripe
          :max-height="tableHeight"
          @selection-change="selectionChange"
        >
          <el-table-column
            align="center"
            width="55"
            label="全选"
            type="selection"
            fixed
          ></el-table-column>
          <el-table-column
            align="center"
            prop="subjectName"
            label="科目"
            min-width="180"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="beginTime"
            label="开放登录时间"
            min-width="180"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="endTime"
            label="截止登录时间"
            min-width="180"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="subjectUuid"
            label="开考口令"
            min-width="180"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="video"
            label="监控视频"
            min-width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.video === 1">单路</span>
              <span v-else-if="scope.row.video === 2">双路</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop=""
            label="考试状态"
            min-width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status === 0" style="color: #67c23a"
                >待开考</span
              >
              <span v-else-if="scope.row.status === 1" style="color: #f56c6c"
                >考试中</span
              >
              <span v-else-if="scope.row.status === 2" style="color: #909399"
                >已结束</span
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop=""
            label="操作"
            min-width="480"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  style="background-color: #da0959"
                  @click="saveOrUpdateTitle(scope.row)"
              >设置题号
              </el-button>
              <el-button
                size="mini"
                style="background-color: #8b2d87"
                @click="openUpdateExamSubject(scope.row)"
                >编辑
              </el-button>
              <el-button
                size="mini"
                style="background-color: #da8309"
                @click="removeExamSubject(scope.row)"
                >移除
              </el-button>
              <el-button
                size="mini"
                style="background-color: #da8309"
                @click="UploadQuestion(scope.row)"
                >上传试题
              </el-button>
              <!-- <el-button
                  size="mini"
                  style="background-color: #09a2da"
                  @click="startOrClose(scope.row,'0','您确定关闭截图')"
              >关闭截图
              </el-button
              >
              <el-button
                  size="mini"
                  style="background-color: #da0959"
                  @click="startOrClose(scope.row,'1','您确定开启截图')"
              >开启截图
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- dialog 弹框 -->
    <ExamSubjectDialog
      :dialogVisibleFu="dialogVisibleFu"
      :dialogText="dialogText"
      :dialogForm="dialogForm"
      :beginTime="beginTime"
      :endTime="endTime"
      @closeDialog="closeDialog"
    />
    <QuestionDialog
      :dialogVisibleQue="dialogVisibleQue"
      :dialogText="dialogText"
      :examId="examId"
      :subjectUuid="subjectUuid"
      :paperDownloadBegin="paperDownloadBegin"
      :paperDownloadEnd="paperDownloadEnd"
      :paperUrl="paperUrl"
      :getExamSubjectLists="getExamSubjectLists"
      @closeDialogQue="closeDialogQue"
    />
    <TitleDialog
        :dialogVisibleTitle="dialogVisibleTitle"
        :dialogText="dialogText"
        :subjectUuid="subjectUuid"
        @closeDialogTitle="closeDialogTitle"
    />
  </div>
</template>

<script>
import ExamSubjectDialog from "c/admin/examination/ExamSubjectDialog";
import QuestionDialog from "c/admin/examination/QuestionDialog";
import TitleDialog from "c/admin/examination/TitleDialog";
import {
  getExamSubjectData,
  deleteSubject,
  startOrCloseVideo,
} from "r/admin/examination";

export default {
  name: "ExaminationSubject",
  data() {
    return {
      examSubjectLists: [], // 当前考试科目数据列表
      // 弹框文字
      dialogText: {
        dialogTitle: "",
        btnText: "",
      },
      dialogVisibleFu: false, // 控制dialog弹框显示与隐藏
      dialogVisibleQue: false, // 控制dialog弹框显示与隐藏
      dialogVisibleTitle: false, // 控制title弹框显示与隐藏
      dialogForm: {}, // 数据对象
      dialogFormRules: {}, // 表单验证规则
      selectData: [], //表格中选中项的数据集合
      selectCount: 0, // 表格中选中项的数量
      ids: [], // 选中的列的id数组集合
      tableHeight: "",
      endTime: "",
      beginTime: "",
      examId: this.$route.query.uuid, //考试id
      subjectUuid: "", //科目id
      paperDownloadBegin: "", //试题开考时间
      paperDownloadEnd: "", //试题结束时间
      paperUrl: "", //上传试题图片
    };
  },
  created() {
    //页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
    // 调用获取科目列表数据的方法
    this.getExamSubjectLists();

    //调用时间
    this.getTime();
    // console.log(this.$route, "11111");
  },
  methods: {
    // 打开设置题号弹框
    saveOrUpdateTitle(row) {
      this.dialogVisibleTitle = true;
      this.dialogText.dialogTitle = "设置题号";
      this.dialogText.btnText = "确 认";
      this.subjectUuid = row.subjectUuid;
    },
    //定义方法，获取高度减去头尾
    getHeight() {
      this.tableHeight = window.innerHeight - 260 + "px";
    },
    //获取endTime和beginTime
    getTime() {
      this.beginTime = this.$route.query.beginTime;
      this.endTime = this.$route.query.endTime;
      // console.log(this.beginTime, this.endTime, "时间");
    },
    // 获取科目列表数据
    async getExamSubjectLists() {
      const { data: res } = await getExamSubjectData({
        examId: this.$route.query.uuid,
        status: "",
      });
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      res.data.forEach((item) => {
        if (item.beginTime) {
          item.beginTime = this.dayjs(item.beginTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
        if (item.endTime) {
          item.endTime = this.dayjs(item.endTime).format("YYYY-MM-DD HH:mm:ss");
        }
      });
      this.examSubjectLists = res.data;
    },

    // 打开新建科目的对话框
    openAddExamSubject() {
      this.dialogForm = {
        examId: this.$route.query.uuid,
        subjectId: "",
        beginTime: "",
        endTime: "",
        video: "2",
        isSfz: "1",
        isHjPhoto: "1",
        isJkPhoto: "1",
        // isZb:"1",
        status: 0,
      };
      this.dialogVisibleFu = true;
      this.dialogText.dialogTitle = "新建科目";
      this.dialogText.btnText = "确 认";
    },
    // 打开编辑科目的对话框
    openUpdateExamSubject(data) {
      // console.log(data, "data");
      // return;
      // console.log(data, "对话框返回");
      // 单选按钮需要绑定字符串值
      data.video = data.video.toString();
      data.isSfz = data.isSfz.toString();
      data.isHjPhoto = data.isHjPhoto.toString();
      data.isJkPhoto = data.isJkPhoto.toString();
      if (data.isZb) {
        data.isZb = data.isZb.toString();
      }

      this.dialogForm = data;
      delete this.dialogForm.paperDownloadBegin;
      delete this.dialogForm.paperDownloadEnd;
      delete this.dialogForm.paperUrl;
      this.dialogVisibleFu = true;
      this.dialogText.dialogTitle = "编辑科目";
      this.dialogText.btnText = "确 认";
    },
    // 关闭弹框
    closeDialog(value) {
      this.dialogVisibleFu = value;

      // 刷新科目列表
      setTimeout(this.getExamSubjectLists(), 2000);
    },

    // 关闭弹框
    closeDialogTitle(value) {
      this.dialogVisibleTitle = value;
      // 刷新科目列表
      setTimeout(this.getExamSubjectLists(), 2000);
    },
    // 移除科目
    async removeExamSubject(data) {
      const confirmResult = await this.$confirm("确认移除该科目？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了删除");

      // 发送请求
      // const { data: res } = await deleteSubject({ids: data.subjectId, examId: this.$route.query.uuid })
      const { data: res } = await deleteSubject({ ids: data.id });
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.data);
      // 刷新科目列表
      this.getExamSubjectLists();
    },
    //上传试题
    UploadQuestion(row) {
      console.log(row, "row");
      this.subjectUuid = row.subjectUuid;
      console.log(JSON.parse( row.paperUrl))
      // let paper=JSON.parse( row.paperUrl)
      // this.paperDownloadBegin = row.paperDownloadBegin;
      // console.log(this.paperDownloadBegin);
      // this.paperDownloadEnd = row.paperDownloadEnd;
      // console.log(this.paperDownloadEnd);
      this.paperUrl = row.paperUrl;
      this.dialogVisibleQue = true;
      this.dialogText.dialogTitle = "上传试题";
      this.dialogText.btnText = "确 认";
    },

    closeDialogQue(value) {
      // console.log(value, "value");
      this.dialogVisibleQue = value;
      this.examSubjectLists = [];
      this.paperDownloadBegin = "";
      this.paperDownloadEnd = "";
      this.paperUrl = "";
      // this.$forceUpdate()
      // window.location.reload()
      // 刷新科目列表
      setTimeout(() => {
        // 刷新科目列表
        this.getExamSubjectLists();
      }, 500);
    },

    // 选中状态改变
    selectionChange(val) {
      // val 指选中的列的数组集合
      // 清空ids数据
      this.selectData = val;
      this.ids = [];
      this.selectCount = val.length;
      val.forEach((item) => {
        this.ids.push(item.id);
      });
    },

    // 批量删除科目
    async removeExamSubjectBatch() {
      if (this.selectCount <= 0)
        return this.$message.info("您还未选择需要删除的科目");

      const confirmResult = await this.$confirm(
        `确认删除所选 ${this.selectCount} 科科目？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        }
      ).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了删除");

      // 发送请求
      const { data: res } = await deleteSubject({ ids: this.ids.join(",") });
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.message);

      // 刷新科目列表
      this.getExamSubjectLists();
    },

    //startOrClose
    async startOrClose(row, status, message) {
      const confirmResult = await this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了操作");

      const { data: res } = await startOrCloseVideo({
        subjectUuid: row.subjectUuid,
        status,
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.message);
    },
  },
  components: {
    ExamSubjectDialog,
    QuestionDialog,
    TitleDialog
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/content";
@import "a/scss/table";
@import "a/scss/page";
</style>
