<template>
  <!-- dialog弹框 -->
  <el-dialog
    :title="this.dialogText.dialogTitle"
    :visible.sync="dialogVisible"
    width="570px"
    center
    @close="resetForm"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <!-- 表单区域 -->
    <el-form
      :model="dialogForm"
      :rules="dialogFormRules"
      ref="dialogFormRef"
      label-width="120px"
      class="demo-ruleForm"
      style="padding: 10px 30px 0 30px"
    >
      <el-form-item label="科目名称：" prop="subjectId" v-if="dialogText.dialogTitle === '新建科目'">
        <el-select
          v-model="dialogForm.subjectId"
          placeholder="请选择考试科目"
        >
          <el-option
            v-for="(item, index) in allObjectLists"
            :key="index"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="科目名称：" prop="subjectName" v-else>
        <el-input
          disabled
          v-model="dialogForm.subjectName"
          placeholder="请选择考试科目"
        ></el-input>
      </el-form-item>
      <el-form-item label="开放登录时间：" prop="beginTime">
        <el-date-picker
          v-model="dialogForm.beginTime"
          placeholder="请选择开放登录时间"
          type="datetime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="截止登录时间：" prop="endTime">
        <el-date-picker
          v-model="dialogForm.endTime"
          placeholder="请选择截止登录时间"
          type="datetime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="监控视频：" prop="video">
        <el-radio-group v-model="dialogForm.video">
          <el-radio label="2">双路</el-radio>
          <el-radio label="1">单路</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上传身份证：" prop="isSfz">
        <el-radio-group v-model="dialogForm.isSfz">
          <el-radio label="1">开</el-radio>
          <el-radio label="0">关</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上传接口照片：" prop="isJkPhoto">
        <el-radio-group v-model="dialogForm.isJkPhoto">
          <el-radio label="1">开</el-radio>
          <el-radio label="0">关</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上传环境照片：" prop="isHjPhoto">
        <el-radio-group v-model="dialogForm.isHjPhoto">
          <el-radio label="1">开</el-radio>
          <el-radio label="0">关</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="开启截图：" prop="isHjPhoto">
        <el-radio-group v-model="dialogForm.isZb">
          <el-radio label="1">开</el-radio>
          <el-radio label="0">关</el-radio>
        </el-radio-group>
      </el-form-item> -->
    </el-form>

    <!-- 底部按钮区域 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancelBtn">取 消</el-button>
      <el-button size="small" type="primary" @click="submitForm" :disabled="isDisable">{{
        this.dialogText.btnText
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAllSubjectData, updateSubject, createSubject } from "r/admin/examination";

export default {
  name: "ExamDialog",
  props: {
    dialogVisibleFu: Boolean,
    dialogText: Object,
    dialogForm: Object,
    beginTime:String,
    endTime:String
  },
  data() {
    return {
      allObjectLists: [], // 全部科目数据列表
      dialogVisible: this.dialogVisibleFu, // 控制dialog弹框显示与隐藏
      // dialogForm: this.dialogForm, // 数据对象
      dialogFormRules: {
        subjectId: [
          { required: true, message: '请选择考试科目', trigger: 'blur' }
        ],
        subjectName: [
          { required: true, message: '请输入科目名称', trigger: 'blur' }
        ],
        beginTime: [
          { required: true, message: '请选择开考时间', trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '请选择结束时间', trigger: 'blur' }
        ]
      }, // 表单验证规则
      isDisable: false, // 提交按钮是否可点击（防重复点击）
    };
  },
  created() {
    this.getAllObjectLists();
    // console.log(this.dialogForm.endTime,"结束时间")
  },
  methods: {
    // 获取全部科目
    async getAllObjectLists() {
      const { data: res } = await getAllSubjectData({});
      // console.log(res,"弹框数据");
      this.allObjectLists = res.data;
    },
    // 重置表单
    resetForm() {
      this.$refs.dialogFormRef.resetFields();
    },

    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.resetForm();
      this.$emit("closeDialog", this.dialogVisible);
    },

    // 确定,提交表单
    async submitForm() {

      this.isDisable = true
      setTimeout(() => {
        this.isDisable = false
      }, 1000)
      //  console.log("比较时间前后",this.dialogForm.beginTime,this.dialogForm.endTime)
       this.$refs.dialogFormRef.validate(async valid => {
        if (!valid) return
        // console.log(this.dialogForm.beginTime)
        this.dialogForm.beginTime = this.dayjs(this.dialogForm.beginTime).format('YYYY-MM-DD HH:mm:ss')
        this.dialogForm.endTime = this.dayjs(this.dialogForm.endTime).format('YYYY-MM-DD HH:mm:ss')
         //校验结束时间是否大于开始时间
        // if(new Date(this.dialogForm.beginTime).getTime()<(new Date()).getTime()) return this.$message.error('开考时间不能早于此时！')
        if(new Date(this.dialogForm.beginTime).getTime() > new Date(this.dialogForm.endTime).getTime()){
              this.$message.error('开考时间必须早于结束时间！')
              return 
        }
        //判断只能选择指定时间范围内的时间
        if(parseInt(new Date(this.beginTime).getTime()) - parseInt(new Date(this.dialogForm.beginTime).getTime()) > 34800000 ){ 
          this.$message({ 
              message: '请选择时间:' + this.beginTime + '至' + this.endTime + '之间',
              type: 'error'
            });
            // console.log(this.beginTime)
            // console.log(new Date(this.dialogForm.beginTime).getTime() , new Date(this.beginTime).getTime() , '比较开始时间')
            return
        }else if((parseInt(new Date(this.dialogForm.endTime).getTime()) - parseInt(new Date(this.endTime).getTime())) > 57599000){
          // console.log(new Date(this.dialogForm.endTime).getTime() , new Date(this.endTime).getTime() , '比较结束时间')
            this.$message({ 
              message: '请选择时间:' + this.beginTime + '至' + this.endTime + '之间',
              type: 'error'
            });
            return
        }

// console.log(this.dialogForm)
        if (this.dialogText.dialogTitle === "编辑科目") {
          // console.log("修 改");
          const { data: res } = await updateSubject(this.dialogForm);
          // console.log(res);
          if (res.code !== 200) return this.$message.error(res.message);
          this.$message.success(res.message);
          
        } else if (this.dialogText.dialogTitle === "新建科目") {
          // console.log("创 建");
          const { data: res } = await createSubject(this.dialogForm);
          // console.log(res);
          if (res.code !== 200) return this.$message.error(res.message);
          this.$message.success(res.message);
        }

        this.cancelBtn();
      })
    },
  },
  watch: {
    dialogVisibleFu() {
      this.dialogVisible = this.dialogVisibleFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/dialog";

</style>
