<template>
  <el-dialog
      :title="this.dialogText.dialogTitle"
      :visible.sync="dialogVisibleTitle"
      width="570px"
      center
      @open="getMsg"
      @close="resetForm"
      :before-close="cancelBtn"
      :close-on-click-modal="false"
  >
    <el-row :gutter="15">
      <el-col :span="13">
        <div class="grid-content bg-purple">
          <el-input
              placeholder="请输入科目"
              v-model="titleSubjectName"
              clearable>
          </el-input>
        </div>
      </el-col>

      <el-col :span="4">
        <div class="grid-content bg-purple">
          <el-button type="primary" @click="addSubject">添加</el-button>
        </div>
      </el-col>
    </el-row>

    <!-- 底部按钮区域 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancelBtn">取 消</el-button>
      <el-button
          size="small"
          type="primary"
          @click="submitForm"
          :disabled="isDisable"
      >{{ this.dialogText.btnText }}</el-button
      >
    </span>
    <div v-for="(value,key,index) in this.subjectObjectTable">
      <el-row style="margin-top: 14px;" :gutter="10">
        <el-col :span="2" style="line-height: 40px;">
          {{ value.titleName }}
        </el-col>
        <el-col :span="13">
          <el-input
              onkeyup="value=value.replace(/[^\d]/g,0)"
              placeholder="请输入题号"
              clearable
              v-model="subjectObjectForm[key].value">
          </el-input>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <el-button type="primary" @click="addTitleSubject(key,subjectObjectForm[key])">添加</el-button>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="grid-content bg-purple">
            <el-button type="primary" @click="deleteTitleSubject(key)">删除</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table
          :data="value.tableData"
          :border="true"
          @selection-change="(val) => handleSelectionChange(key, val)"
          style="width: 100%">
        <el-table-column
            type="selection"
            align="center"
            width="110">
        </el-table-column>
        <el-table-column
            label="题号"
            align="center"
            prop="titleNumber"
            width="201">
          <template slot-scope="scope">
            <span style="color: black;">{{ value.titleName }}</span>-<span
              style="color: black;">{{ scope.row.titleNumber }}</span>
          </template>
        </el-table-column>

        <el-table-column
            align="center"
            label="操作"
            width="202"
        >
          <template slot-scope="scope">
            <el-button type="warning" @click="deleteTitleNum(key,scope.row)">删除题号</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import {
  listBySubjectUuid,
  saveOrUpdateTitle
} from "r/admin/examination";

export default {
  name: "TitleDialog",
  props: {
    dialogVisibleTitle: Boolean,
    dialogText: Object,
    subjectUuid: String
  },
  data() {
    return {
      dialogVisible: this.dialogVisibleTitle, // 控制dialog弹框显示与隐藏
      isDisable: false, // 提交按钮是否可点击（防重复点击）
      titleSubjectName: '',
      input: '',
      tableData: [],
      subjectObjectTable: {},
      subjectObjectForm: {},
      subjectObjectDelete: {}
    }
  },
  methods: {
    async getMsg() {
      const {data: res} = await listBySubjectUuid(
          {
            subjectUuid: this.subjectUuid
          }
      )
      if (res.code !== 200) return this.$message.error(res.message);
      // 如果没有data为null  如果有值 titleNumber不为空
      // id: 15
      // subjectUuid: "eh9u4h"
      // titleNumber: "{\"语文\":\"1,2,3\",\"数学\":\"2,3,4\",\"英语\":\"3,4,5\"}"
      console.log(res)
      if (res.data) {
        // 给table赋值
        let parse = JSON.parse(res.data.titleNumber);
        //遍历对象属性
        // titleName: '语文',
        //     id: 15,
        //     subjectUuid: 'dczw4p',
        //     tableData: []
        Object.keys(parse).forEach((value, index) => {
          let titleNumArr = parse[value].split(",")
          let titleArr = []
          titleNumArr.forEach(item => {
            titleArr.push({
              titleNumber: item
            })
          })
          this.$set(this.subjectObjectTable, "table" + (index + 1), {
            titleName: value,
            subjectUuid: this.subjectUuid,
            tableData: titleArr
          })

          // 给input框赋值
          this.$set(this.subjectObjectForm, "table" + (index + 1), {
            name: value,
            value: ''
          })
        })
      }
    },
    resetForm() {

    },
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeDialogTitle", this.dialogVisible);
      setTimeout(() => {
        this.titleSubjectName = ''
        this.subjectObjectTable = {}
      }, 400);
    },
    submitForm() {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      // 将数据改成后台需要的格式类型
      // 包括3种情况 1. 数据库没有 也没有操作     subjectObjectTable:{}
      //           2. 数据库没有 操作完也删除了  subjectObjectTable:{table1:{
      //                   titleName:'语文',
      //                   subjectUuid:'dep',
      //                   titleData :[{titleNum}]
      //           },}
      //           3.数据库有 操作删除了 subjectObjectTable:{table1:{
      //                   titleName:'语文',
      //                   subjectUuid:'dep',
      //                   titleData :[]
      //           },}
      //  第一种情况不考虑
      if (Object.keys(this.subjectObjectTable).length > 0) {
        let objStr = {}
        Object.keys(this.subjectObjectTable).forEach(item => {
          let titleName = this.subjectObjectTable[item].titleName;
          let titleStr = ""
          this.subjectObjectTable[item].tableData.forEach(item => {
            titleStr = titleStr + item.titleNumber + ","
          })
          // 去掉最后一个,
          if (titleStr) {
            let numStr = titleStr.substring(0, titleStr.length - 1).split(",")
            numStr.sort(function (a, b) {
              return parseInt(a) - parseInt(b)
            })
            objStr[titleName] = numStr.toString()
          }

        })
        let submitData = {
          subjectUuid: this.subjectUuid,
          titleNumber: JSON.stringify(objStr)
        }
        saveOrUpdateTitle(submitData)
      }
      this.cancelBtn();
    },
    addSubject() {
      if (this.titleSubjectName) {
        // 判断是否有重复科目
        for (let item in this.subjectObjectTable) {
          if (this.subjectObjectTable[item].titleName === this.titleSubjectName) {
            return this.$message.warning("该科目已经存在")
          }
        }
        // 添加table+1的对象
        let tableKey = "table" + (Object.keys(this.subjectObjectTable).length + 1);

        this.$set(this.subjectObjectTable, tableKey, {
          titleName: this.titleSubjectName,
          subjectUuid: this.subjectUuid,
          tableData: []
        })
        this.$set(this.subjectObjectForm, tableKey, {
          name: this.titleSubjectName,
          value: ''
        })
        this.titleSubjectName = ''
      }
    },
    addTitleSubject(key, obj) {
      let flag = false;
      if (obj.value) {
        this.subjectObjectTable[key].tableData.forEach(item => {
          if (parseInt(obj.value) == item.titleNumber) {
            flag = true;
            return this.$message.info(this.subjectObjectTable[key].titleName + "科目的第" + obj.value + "题已经添加,请勿重复添加");
          }
        })
        if (!flag) {
          this.subjectObjectTable[key].tableData.push({
            titleNumber: obj.value
          })
        }
      }
    },
    handleSelectionChange(key, val) {
      this.subjectObjectDelete[key] = val
    },
    async deleteTitleNum(key, row) {
      const confirmResult = await this.$confirm(`确认删除题号：${row.titleNumber} ？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了删除");


      this.subjectObjectTable[key].tableData.forEach((item, index) => {
        if (item === row) {
          this.subjectObjectTable[key].tableData.splice(index, 1)
          this.$message.success("删除成功");
        }
      })
    },
    async deleteTitleSubject(key) {
      if (!this.subjectObjectDelete[key]) {
        return;
      }
      if (this.subjectObjectDelete[key].length == 0) {
        return;
      }
      console.log(this.subjectObjectDelete[key]);
      const confirmResult = await this.$confirm(`确认删除所选中题号 ？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了删除");
      this.subjectObjectTable[key].tableData = this.subjectObjectTable[key].tableData.filter((x) => !this.subjectObjectDelete[key].some((item) => x.titleNumber == item.titleNumber))
      this.$message.success("删除成功");

      console.log(this.subjectObjectTable)
    }
  },
  watch: {
    dialogVisibleTitle() {
      this.dialogVisible = this.dialogVisibleTitle;
    }
  }
}
</script>

<style scoped lang="scss">
@import "a/scss/dialog";

::v-deep td {
  border-bottom: 1px solid #ebeef5 !important;
}
</style>